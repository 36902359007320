import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import "./header.css";

function isActive({ isCurrent, isPartiallyCurrent, href }) {
  if (isCurrent) {
    return { className: "active" };
  }
  if (isPartiallyCurrent && href !== "/") {
    return { className: "partially_active" };
  }
  return null;
}

const createDiv = () => {
  var x = document.getElementById("main_menu");
  if (x.className === "top_menu") {
    x.className += " responsive";
  } else {
    x.className = "top_menu";
  }
};

const Header = ({ siteTitle, menuLinks, siteSubTitle }) => (
  <header>
    <div>
      <div id="site_title" style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </div>
      <div id="site_sub_title">{siteSubTitle}</div>
      <div className="top_menu" id="main_menu">
        <nav>
          <ul className="top_menu menu">
            {menuLinks.map((link) => (
              <li key={link.name}>
                <Link to={link.link} getProps={isActive}>
                  {link.name}
                </Link>
                {link.subMenu && link.subMenu.length > 0 ? (
                  <ul className="sub_menu menu">
                    {link.subMenu.map((subLink) => (
                      <li key={subLink.name}>
                        <Link to={subLink.link} getProps={isActive}>
                          {subLink.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul>
          <div
            role="button"
            tabIndex={0}
            className="icon hamburger"
            onClick={createDiv}
            onKeyDown={createDiv}
            aria-label="Show Menu"
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </nav>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
