import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Seo from "./seo";
import Header from "./header";
import "./layout.css";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import CookieConsent from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

function getBreadcrumbs(pageContext, title, addCrumbs) {
  let breadCumbs = "";
  if (addCrumbs) {
    const customCrumbLabel = title;
    const {
      breadcrumb: { crumbs },
    } = pageContext;
    breadCumbs = <Breadcrumb crumbs={crumbs} crumbLabel={customCrumbLabel} />;
  }
  return breadCumbs;
}

function updateSlug(allMdx) {
  allMdx.nodes.forEach((element) => {
    var newSlug = element.slug;
    if (newSlug[0] !== "/") {
      newSlug = "/" + newSlug;
    }
    if (newSlug.slice(-1) !== "/") {
      newSlug = newSlug + "/";
    }
    element.slug = newSlug;
  });
}

function getMenuItems(allMdx, metaMenuLinks) {
  var menuItems = {};
  allMdx.nodes.forEach((element) => {
    var splitted = element.slug.split("/");
    var menuDepth = splitted.length - 3;
    menuDepth = element.slug === "/" ? 0 : menuDepth;
    if (menuDepth === 1) {
      menuItems[splitted[1]]["subElements"][splitted[2]] = {
        element: element,
        subElements: {},
      };
    } else {
      menuItems[splitted[1]] = { element: element, subElements: {} };
    }
  });
  var menuItemsList = [];
  for (var key in menuItems) {
    var subMenu = [];
    for (var subKey in menuItems[key].subElements) {
      subMenu.push({
        name: menuItems[key].subElements[subKey].element.frontmatter.menuTitle
          ? menuItems[key].subElements[subKey].element.frontmatter.menuTitle
          : menuItems[key].subElements[subKey].element.frontmatter.title,
        link: menuItems[key].subElements[subKey].element.slug,
        order: menuItems[key].subElements[subKey].element.frontmatter.order,
        subMenu: {},
      });
    }
    subMenu.sort((a, b) =>
      a.order > b.order ? 1 : b.order > a.order ? -1 : 0
    );
    menuItemsList.push({
      name: menuItems[key].element.frontmatter.menuTitle
        ? menuItems[key].element.frontmatter.menuTitle
        : menuItems[key].element.frontmatter.title,
      link: menuItems[key].element.slug,
      order: menuItems[key].element.frontmatter.order,
      subMenu: subMenu,
    });
  }
  menuItemsList.sort((a, b) =>
    a.order > b.order ? 1 : b.order > a.order ? -1 : 0
  );

  menuItemsList = menuItemsList.concat(metaMenuLinks);
  menuItemsList = menuItemsList.filter((item) => item.name !== "dummy");

  return menuItemsList;
}

const Layout = ({
  children,
  location,
  pageContext,
  title,
  description,
  addCrumbs,
  isArticle,
  tableOfContents = "",
  menuTitle = "",
  image = undefined,
}) => {
  const data = useStaticQuery(graphql`
    query siteQuery {
      site {
        siteMetadata {
          title
          subTitle
          menuLinks {
            name
            link
            subMenu {
              link
              name
            }
          }
        }
      }
      allMdx(sort: { fields: slug }) {
        nodes {
          slug
          tableOfContents
          frontmatter {
            order
            title
            description
            menuTitle
          }
        }
      }
    }
  `);

  if (!menuTitle || menuTitle.length == 0) {
    menuTitle = title;
  }

  updateSlug(data.allMdx);
  let menuItemsList = getMenuItems(
    data.allMdx,
    data.site.siteMetadata.menuLinks
  );

  let breadCumbs = getBreadcrumbs(pageContext, menuTitle, addCrumbs);

  return (
    <>
      {" "}
      <Seo title={title} description={description} image={image}></Seo>
      <Header
        menuLinks={menuItemsList}
        siteTitle={data.site.siteMetadata.title}
        siteSubTitle={data.site.siteMetadata.subTitle}
      />
      <CookieConsent
        location="bottom"
        buttonText="Acceptera"
        declineButtonText="X"
        cookieName="gatsby-gdpr-google-analytics"
        enableDeclineButton={true}
        flipButtons={true}
        expires={365}
        onAccept={() => {
          initializeAndTrack(location);
        }}
      >
        För att förbättra anbvändarupplevelsen använder vi Google Analtyics för
        att kunna analysera besöksstatistik. Vill du godkänna detta?
      </CookieConsent>
      <div id="mainContainer">
        <main>
          {breadCumbs}
          <div className={isArticle ? "article" : ""}>
            {tableOfContents} {children}
          </div>
        </main>
        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          © {data.site.siteMetadata.title}, {new Date().getFullYear()}
        </footer>
      </div>
    </>
  );
};

export default Layout;
